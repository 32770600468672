import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 23 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M23 12c0 6.422-5.147 11.625-11.5 11.625a11.3 11.3 0 0 1-3.404-.52c.469-.774 1.169-2.04 1.429-3.047.139-.544.714-2.766.714-2.766.375.722 1.47 1.336 2.634 1.336 3.468 0 5.967-3.225 5.967-7.233 0-3.839-3.102-6.712-7.09-6.712-4.961 0-7.6 3.365-7.6 7.036 0 1.706.9 3.83 2.333 4.504.218.104.334.057.385-.154.037-.16.231-.952.32-1.317a.35.35 0 0 0-.08-.333c-.468-.586-.848-1.655-.848-2.653 0-2.564 1.92-5.044 5.194-5.044 2.824 0 4.804 1.945 4.804 4.73 0 3.145-1.572 5.325-3.617 5.325-1.127 0-1.976-.943-1.702-2.1.325-1.383.95-2.874.95-3.872 0-.89-.473-1.636-1.455-1.636-1.155 0-2.083 1.204-2.083 2.822 0 1.03.344 1.725.344 1.725S7.558 18.58 7.35 19.49c-.232 1.003-.14 2.418-.042 3.337C3.033 21.136 0 16.927 0 12 0 5.578 5.147.375 11.5.375S23 5.578 23 12"
    }, null, -1)
  ])))
}
export default { render: render }